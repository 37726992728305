import { Engine, Render, Runner, World, Bodies, Composite, Composites, Mouse, MouseConstraint, Events, Body } from 'matter-js';

async function fetchSVGs() {
    const responses = await Promise.all([
        fetch('/dist/brand/pageNotFound/four.svg'),
        fetch('/dist/brand/pageNotFound/zero.svg'),
        fetch('/dist/brand/pageNotFound/four-dark.svg'),
        fetch('/dist/brand/pageNotFound/zero-dark.svg')
    ]);
    return Promise.all(responses.map(res => res.text()));
}

function svgToImage(svgContent, width, height) {
    const svgBlob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    const img = new Image();
    img.src = url;
    img.width = width;
    img.height = height;
    return img;
}

let fourImage, zeroImage, fourImageD, zeroImageD;
let color = "#ffffff";
let dark = true;
const body = document.querySelector('body');

fetchSVGs().then(([fourSvg, zeroSvg, fourSvgD, zeroSvgD]) => {
    fourImage = svgToImage(fourSvg, 364, 450);
    zeroImage = svgToImage(zeroSvg, 364, 450);
    fourImageD = svgToImage(fourSvgD, 364, 450);
    zeroImageD = svgToImage(zeroSvgD, 364, 450);

    createMatterWorld(fourImage, zeroImage, color);

});

const local = window.localStorage.getItem('dark');
const html = document.querySelector('html');

if (local) {
    dark = JSON.parse(local)
} else {
    window.localStorage.setItem('dark', false)
    dark = false
}

const setDarkMode = () => {
    dark ? html.classList.add('dark') : html.classList.remove('dark');
    color = dark ? "#ffffff" : "#000000";
    createMatterWorld(dark ? fourImage : fourImageD, dark ? zeroImage : zeroImageD, color);
    window.localStorage.setItem('dark', dark);
}

setDarkMode();

document.querySelector('#dark').addEventListener('click', () => {
    dark = !dark;
    setDarkMode()
});

function createMatterWorld(four, zero, colour) {
    // Create engine
    const engine = Engine.create();
    const world = engine.world;

    // Create renderer
    const canvas = document.getElementById('animation');
    if (canvas) {
        const render = Render.create({
            canvas: canvas,
            engine: engine,
            options: {
                width: canvas.offsetWidth,
                height: canvas.offsetHeight,
                showAngleIndicator: false,
                wireframes: false
            }
        });

        // Create runner
        const runner = Runner.create();
        Runner.run(runner, engine);

        let shapes = [];
        let delayCounter = 0;
        const shapeInterval = 500; // Reduced interval between shape creation to 500ms
        const shapeSize = window.innerWidth >= 1280 ? 120 : 60; // Size of shapes

        // Function to add shapes in the desired sequence
        function addShapesSequence() {
            const sequence = [four, zero, four]; // Sequence of images
            sequence.forEach((image, index) => {
                const shape = Bodies.rectangle(100 + (shapeSize - 50) * index, -shapeSize, -shapeSize, shapeSize, {
                    render: {
                        sprite: {
                            texture: image.src,
                            xScale: shapeSize / 400,
                            yScale: shapeSize / 400
                        }
                    }
                });
                shapes.push(shape);
                World.add(world, shape);
            });
        }

        // Initial shape addition
        addShapesSequence();

        // Create moving stairs
        const stairCount = Math.floor(render.bounds.max.y);
        const stairs = Composites.stack(0, window.innerWidth >= 1280 ? 400 : 200, stairCount + 2, 1, 0, 0, (x, y, column) => {
            return Bodies.rectangle(x - 100, y + column * 50, 200, 800, {
                isStatic: true,
                render: {
                    fillStyle: "#00000000",
                    strokeStyle: colour,
                    lineWidth: 2
                }
            });
        });

        Composite.add(world, stairs);

        // Add mouse control
        const mouse = Mouse.create(canvas);
        const mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });
        World.add(world, mouseConstraint);

        // Time scaling for slow motion
        let timeScaleTarget = 1;
        let lastTime = Date.now();
        Events.on(engine, 'afterUpdate', (event) => {
            const timeScale = (event.delta || (1000 / 60)) / 1000;

            // Tween the timescale for slow motion
            if (mouse.button === -1) {
                engine.timing.timeScale += (timeScaleTarget - engine.timing.timeScale) * 1 * timeScale;
            } else {
                engine.timing.timeScale = 1;
            }

            // Move stairs and shapes to create a continuous effect
            stairs.bodies.forEach(body => {
                Body.translate(body, { x: -100 * timeScale, y: -50 * timeScale });

                if (body.position.x < -50) {
                    Body.setPosition(body, {
                        x: 60 * (stairs.bodies.length - 1),
                        y: 30 + render.bounds.max.y + (body.bounds.max.y - body.bounds.min.y) * 0.5
                    });
                    Body.setVelocity(body, { x: 0, y: 0 });
                }
            });

            // Ensure shapes exist before trying to move them
            if (shapes.length > 0) {
                shapes.forEach(body => {
                    if (body.position.x < -50 || body.position.y > render.bounds.max.y + 100) {
                        // Remove shape if it's out of bounds
                        World.remove(world, body);
                    }
                });
                shapes = shapes.filter(body => world.bodies.includes(body));
            }

            // Add new shapes with a shorter delay
            if (shapes.length < 3 && Date.now() - delayCounter >= shapeInterval) {
                addShapesSequence();
                delayCounter = Date.now();
            }

        });

        // Run the renderer
        Render.run(render);
    }
}