import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import focus from "@alpinejs/focus"
import intersect from "@alpinejs/intersect"
import breakpoint from "alpinejs-breakpoints"
Alpine.plugin(intersect)
Alpine.plugin(breakpoint)
Alpine.plugin(collapse)
Alpine.plugin(focus)
window.Alpine = Alpine
window.AlpineBreakpointPluginBreakpointsList = [
	"xs",
	"sm",
	"md",
	"lg",
	"xl",
	"2xl",
	"3xl",
]
window.Alpine.start()

import "./modules/matter"
import "./modules/gsap"

const datetime = document.querySelector('#date-time');
const prefixZero = (val) => {
	if (val >= 10) {
		return val
	}
	else {
		return `0${val}`
	}
}

if (datetime) {
	const date = new Date();

	const year = date.getFullYear()
	const month = date.getMonth()
	const day = date.getDate()
	const hour = date.getHours()
	const min = date.getMinutes()
	const sec = date.getSeconds()

	const complied = `${year}:${prefixZero(month)}:${prefixZero(day)}:${prefixZero(hour)}:${prefixZero(min)}:${prefixZero(sec)}`;
	datetime.innerHTML = complied;
}
