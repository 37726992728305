import 'lenis/dist/lenis.css'
import Lenis from "lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

window.addEventListener('DOMContentLoaded', () => {
   setTimeout(() => {
      
      const marqueeContainer = document.querySelectorAll(".marquee-container");
      
      marqueeContainer.forEach((container) => {
         const marqueeContent = container.children[0];
         const duplicatedContent = marqueeContent.cloneNode(true);
         container.appendChild(duplicatedContent);
      
         const contentWidth = marqueeContent.offsetWidth;
      
         gsap.to(container.children, {
            x: -contentWidth,
            duration: 20 + Math.floor(Math.random() * 4),
            ease: "linear",
            repeat: -1,
            onRepeat: function () {
               gsap.to(".marquee-content", {
                  delay: 0.1,
                  x: 0,
                  duration: 0
               });
            },
         });
      });
      
      gsap.registerPlugin(ScrollTrigger);
      if (document.querySelector('.pin-text')) {
         // Pin & Scroll Text
         gsap.to(".pin-text", {
            x: () => -(document.querySelector(".pin-text").offsetWidth - document.querySelector(".pin-text-container").offsetWidth + 50),
            ease: "power2.out",
            duration: 2,
            scrollTrigger: {
               trigger: ".pin-text-container",
               pin: true,
               scrub: 0.5,
               start: "top top",
               end: "bottom top"
            }
         });
      }

      // Card Stack
      if (window.innerWidth >= 1280 && document.querySelector('.card-container')) {
         let cards = gsap.utils.toArray(".card");
         let stickDistance = 1950;
         let tl = gsap.timeline();
      
         cards.forEach((card) => {
            tl.from(card, {
               y: 800,
               opacity: 0,
               borderRadius: 0,
               duration: 2
            });
         });
      
         let pinTL = gsap.timeline({
            scrollTrigger: {
               trigger: ".card-container",
               start: "top 0",
               end: () => "+=" + stickDistance,
               pin: true,
               pinSpacing: false,
               scrub: 1,
            }
         });
      
         pinTL.add(tl); // Add the animation timeline to the pinning timeline
      }
      
      // Cursor
      const cursor = document.querySelector("#cursor");
      
      const followMouse = (el, x, y) => {
         gsap.to(el, {
            duration: 0.5,
            left: x,
            top: y,
            ease: 'power2.out'
         });
      }

      document.addEventListener('mousemove', e => {
         followMouse(cursor, e.clientX, e.clientY);
      });
      
      // Smooth Scroll
      const scroller = document.querySelector('#scroller');

      const lenis = new Lenis();

      lenis.on('scroll', (e) => {
         ScrollTrigger.update();

         // Scroll Progress
         const now = e.targetScroll;
         const total = e.dimensions.scrollHeight - e.dimensions.height;

         const scrollPercentage = (now / total) * 100;
         scroller.style.height = `${scrollPercentage}%`;

         // Logo
         const logo = document.querySelector('#logo');
         if (logo) {
            logo.style.transform = `translateY(${now}px)`
         }
      });

      
      gsap.ticker.add((time)=>{
         lenis.raf(time * 1000)
      })
      lenis.scrollTo(0,0)
      
      gsap.ticker.lagSmoothing(0)
   }, 1000);
})

